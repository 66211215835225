var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            inline: true,
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "140px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "name", prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "路径", prop: "path" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.path,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "path", $$v)
                  },
                  expression: "form.path",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "vue文件路径", prop: "component" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.component,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "component", $$v)
                  },
                  expression: "form.component",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "重定向", prop: "redirect" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.redirect,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "redirect", $$v)
                  },
                  expression: "form.redirect",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.meta.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.meta, "title", $$v)
                  },
                  expression: "form.meta.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图标", prop: "remixIcon" } },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    "popper-class": "icon-selector-popper",
                    width: "292",
                    trigger: "hover",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { slot: "reference" },
                    slot: "reference",
                    model: {
                      value: _vm.form.meta.remixIcon,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.meta, "remixIcon", $$v)
                      },
                      expression: "form.meta.remixIcon",
                    },
                  }),
                  _c("icon-table", { on: { "handle-icon": _vm.handleIcon } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "badge", prop: "badge" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.meta.badge,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.meta, "badge", $$v)
                  },
                  expression: "form.meta.badge",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sort" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "隐藏", prop: "hidden" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.hidden,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "hidden", $$v)
                  },
                  expression: "form.hidden",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "始终显示当前节点", prop: "alwaysShow" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.alwaysShow,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "alwaysShow", $$v)
                  },
                  expression: "form.alwaysShow",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "自定义svg图标", prop: "isCustomSvgIcon" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.meta.isCustomSvgIcon,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.meta, "isCustomSvgIcon", $$v)
                  },
                  expression: "form.meta.isCustomSvgIcon",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "固定", prop: "affix" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.meta.affix,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.meta, "affix", $$v)
                  },
                  expression: "form.meta.affix",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "无缓存", prop: "noKeepAlive" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.meta.noKeepAlive,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.meta, "noKeepAlive", $$v)
                  },
                  expression: "form.meta.noKeepAlive",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "不显示多标签页", prop: "tagHidden" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.meta.tagHidden,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.meta, "tagHidden", $$v)
                  },
                  expression: "form.meta.tagHidden",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }