<template>
  <div class="menuManagement-container">
    <el-row>
      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <vab-query-form>
          <vab-query-form-top-panel :span="12">
            <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
              添加
            </el-button>
          </vab-query-form-top-panel>
        </vab-query-form>
        <el-table
          v-loading="listLoading"
          stripe
          :data="list"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          border
          default-expand-all
          row-key="path"
        >
          <el-table-column align="center" label="序号" width="55">
            <template #default="{ $index }">
              {{ $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="name"
            prop="name"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            label="路径"
            prop="path"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            label="是否隐藏"
            show-overflow-tooltip
          >
            <template #default="{ row }">
              <span>
                {{ row.hidden ? '是' : '否' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="是否一直显示当前节点"
            show-overflow-tooltip
          >
            <template #default="{ row }">
              <span>
                {{ row.alwaysShow ? '是' : '否' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="vue文件路径"
            prop="component"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            label="重定向"
            prop="redirect"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            label="标题"
            prop="meta.title"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column align="center" label="图标" show-overflow-tooltip>
            <template #default="{ row }">
              <span v-if="row.meta">
                <vab-remix-icon
                  v-if="row.meta.remixIcon"
                  :icon="row.meta.remixIcon"
                />
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="是否固定"
            show-overflow-tooltip
          >
            <template #default="{ row }">
              <span v-if="row.meta">
                {{ row.meta.affix ? '是' : '否' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="是否无缓存"
            show-overflow-tooltip
          >
            <template #default="{ row }">
              <span v-if="row.meta">
                {{ row.meta.noKeepAlive ? '是' : '否' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="badge" show-overflow-tooltip>
            <template #default="{ row }">
              <span v-if="row.meta">
                {{ row.meta.badge }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="操作"
            show-overflow-tooltip
            width="180"
          >
            <template #default="{ row }">
              <el-button type="text" @click="handleAdd(row)">子菜单</el-button>
              <el-button type="text" @click="handleEdit(row)">编辑</el-button>
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <edit ref="edit" @fetch-data="fetchData"></edit>
  </div>
</template>

<script>
  import { getRouterList as getList } from '@/api/router'
  import { doDelete, getTree } from '@/api/menuManagement'
  import Edit from './components/MenuManagementEdit'

  export default {
    name: 'MenuManagement',
    components: { Edit },
    data() {
      return {
        data: [],
        defaultProps: {
          children: 'children',
          label: 'label',
        },
        list: [],
        listLoading: true,
      }
    },
    async created() {
      // const roleData = await getTree()
      // this.data = roleData.data
      await this.fetchData()
    },
    methods: {
      handleAdd(row) {
        this.$refs['edit'].addSun(row)
      },
      handleEdit(row) {
        if (row.path && row.component) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        if (row.path) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await doDelete({ ID: row.ID })
            this.$baseMessage(msg, 'success')
            await this.fetchData()
          })
        }
      },
      async fetchData() {
        this.listLoading = true
        const { data } = await getTree()
        this.list = data
        this.listLoading = false
      },
      handleNodeClick(option) {
        console.log(option)
        this.fetchData()
      },
    },
  }
</script>
