import request from '@/utils/request'

export function getTree(data) {
  return request({
    url: '/auth/menu/index',
    method: 'get',
    data,
  })
}

export function doEdit(data) {
  return request({
    url: '/auth/menu/edit',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/auth/menu/delete',
    method: 'post',
    data,
  })
}

export const addMenuAuthority = (data) => {
  return request({
    url: '/auth/authority/add-authority',
    method: 'post',
    data,
  })
}

export const getMenuAuthority = (data) => {
  return request({
    url: '/auth/menu/authority-menu',
    method: 'post',
    data,
  })
}
